import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import bgImage from '../img/annaluczycka-bg.jpg';

import '../styles/index.scss';

const TemplateWrapper = ({ children }) => (
    <StaticQuery
        query={graphql`
            query HeadingQuery {
                site {
                    siteMetadata {
                        title
                        description
                    }
                }
            }
        `}
        render={data => (
            <div>
                <Helmet>
                    <html lang="pl" />
                    <title>{data.site.siteMetadata.title}</title>
                    <meta charSet="utf-8" />
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1"
                    />
                    <meta
                        name="description"
                        content={data.site.siteMetadata.description}
                    />
                    <meta name="theme-color" content="#24a8bd" />
                    <meta name="image" content={bgImage} />
                    <meta
                        itemprop="name"
                        content="Anna Łuczycka - Fizjoterapia okołoporodowa"
                    />
                    <meta
                        itemprop="description"
                        content={data.site.siteMetadata.description}
                    />
                    <meta itemprop="image" content={bgImage} />
                    <meta property="og:type" content="business.business" />
                    <meta
                        property="og:title"
                        content={data.site.siteMetadata.title}
                    />
                    <meta property="og:url" content="/" />
                    <meta name="og:image" content={bgImage} />
                    <meta name="og:locale" content="pl_PL" />
                </Helmet>
                <div>{children}</div>
            </div>
        )}
    />
);

export default TemplateWrapper;
