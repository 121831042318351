import React from 'react';
import { node, oneOf, oneOfType, string } from 'prop-types';

export default function Section({ title, children, modificator, intro }) {
    const modificatorClass = modificator ? `section--${modificator}` : '';
    return (
        <div className={`section ${modificatorClass}`}>
            {title && <h3 className="section__title">{title}</h3>}
            {intro && <div className="section__intro">{intro}</div>}
            {children}
        </div>
    );
}

Section.propTypes = {
    children: node,
    modificator: oneOf(['dark', 'blue']),
    title: string,
    intro: oneOfType([string, node]),
    introLink: string,
};
